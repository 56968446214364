import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import {
  type CellContext,
  type ColumnDef,
  type ColumnFiltersState,
  type GroupingState,
  type Row,
  type RowSelectionState,
  type TableState,
  type VisibilityState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import useStore from 'model/store';
import { Fragment, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import type { Data } from 'react-csv/lib/core';
import {
  HiOutlineArrowDownTray,
  HiOutlineArrowsUpDown,
  HiOutlineBarsArrowDown,
  HiOutlineBarsArrowUp,
  HiOutlineBolt,
  HiOutlineDocument,
  HiOutlineMagnifyingGlass,
  HiOutlineRectangleStack,
  HiOutlineXMark,
} from 'react-icons/hi2';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'translations/Intl';
import type { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';
import Button from '../Form/Button';
import StyledButton from '../Form/Button/StyledButton';
import Input from '../Form/Input';
import Select from '../Form/Select/Select';
import PrivateWrapper from '../PrivateWrapper/PrivateWrapper';
import Tooltip from '../Tooltip';
import Transition from '../Transition';
import CheckboxCell, {
  isDisableableItem,
} from './components/CheckboxCell/CheckboxCell';
import ExpanderCell from './components/ExpanderCell';
import FilterMenu from './components/FilterMenu/FilterMenu';
import { SELECT_ID } from './components/FilterMenu/components/Content/Content';
import IndeterminateCheckbox from './components/IndeterminateCheckbox';
import Pagination from './components/Pagination/Pagination';
import RenderedCell from './components/RenderedCell';
import SkeletonLoader from './components/SkeletonLoader';

const EXPANDER_ID = 'expander';
export const DELETE_ID = 'delete';
export const ACTION_ID = 'actions';

interface EnabledFeatures {
  enableGlobalFilter?: boolean;
  enableCsvExport?: boolean;
  enableColumnSelection?: boolean;
  enableRowSelection?: boolean;
  enablePageSize?: boolean;
  enablePagination?: boolean;
  enableColumnFilter?: boolean;
}

const defaultEnabledFeatures: EnabledFeatures = {
  enableGlobalFilter: true,
  enableCsvExport: true,
  enableColumnSelection: true,
  enableRowSelection: false,
  enablePageSize: true,
  enablePagination: true,
  enableColumnFilter: true,
};

export type SelectedActionItem =
  | {
      onClick: () => void;
      text: string;
      permission: HasuraPermissions;
      icon?: JSX.Element;
      dataTestId?: string;
    }
  | undefined;

interface TableProps<T> {
  id: string;
  columns: ColumnDef<T, any>[];
  data: T[];
  getRowId: (originalRow: T) => string;
  loading?: boolean;
  initialState?: Partial<TableState>;
  renderRowSubComponent?: {
    render: (row: Row<T>) => JSX.Element;
    expanderColumnId: string;
  };
  dataCallback?: (data: Row<T>[]) => void;
  enabledFeatures?: EnabledFeatures;
  onSelectedChange?: (row: Row<T>) => void;
  renderSelectedAction?: (
    selection: Row<T>[],
    resetSelection: () => void,
  ) => SelectedActionItem[];
  renderAdditionalAction?: JSX.Element;
  dataTestId?: string;
}

export default function Table<T>({
  id,
  columns,
  data,
  getRowId,
  loading,
  initialState,
  renderRowSubComponent,
  dataCallback,
  enabledFeatures,
  onSelectedChange,
  renderSelectedAction,
  renderAdditionalAction,
  dataTestId,
}: TableProps<T>) {
  const intl = useIntl();
  const userRoles = useStore((state) => state.user)?.roles;
  const [, setParams] = useSearchParams();
  const [globalFilter, setGlobalFilter] = useState('');
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    initialState?.rowSelection ?? {},
  );
  const [grouping, setGrouping] = useState<GroupingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    initialState?.columnFilters ?? [],
  );
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    Object.keys(JSON.parse(localStorage.getItem(`table-columns-${id}`) ?? '{}'))
      .length === 0 && initialState?.columnVisibility
      ? initialState.columnVisibility
      : JSON.parse(localStorage.getItem(`table-columns-${id}`) ?? '{}'),
  );

  const {
    enableGlobalFilter,
    enableRowSelection,
    enableCsvExport,
    enableColumnSelection,
    enablePageSize,
    enablePagination,
    enableColumnFilter,
  } = {
    ...defaultEnabledFeatures,
    ...enabledFeatures,
  };

  function Expander({ row }: CellContext<T, unknown>) {
    return ExpanderCell({
      row,
      columnId: renderRowSubComponent?.expanderColumnId ?? '',
    });
  }

  const rowSelectionColumn: ColumnDef<T, any>[] = enableRowSelection
    ? [
        {
          id: SELECT_ID,
          header: ({ table: t }) => (
            <IndeterminateCheckbox
              checked={t.getIsAllRowsSelected()}
              indeterminate={t.getIsSomeRowsSelected()}
              onChange={t.getToggleAllRowsSelectedHandler()}
              header
            />
          ),
          cell: ({ row }) => (
            <CheckboxCell
              row={row}
              onSelectedChange={onSelectedChange}
              initialState={initialState}
            />
          ),
          enableGrouping: false,
        },
      ]
    : [];

  const expanderColumn: ColumnDef<T, any>[] = renderRowSubComponent
    ? [
        {
          id: EXPANDER_ID,
          cell: Expander,
          enableGrouping: false,
        },
      ]
    : [];

  const table = useReactTable({
    data,
    columns: [...rowSelectionColumn, ...expanderColumn, ...columns],
    initialState: {
      ...initialState,
      pagination: { pageSize: 20 },
    },
    state: {
      rowSelection,
      columnVisibility,
      globalFilter,
      columnFilters,
      grouping,
    },
    globalFilterFn: (row, columnId, filterValue) => {
      const safeValue: string =
        typeof row.getValue(columnId) === 'number'
          ? String(row.getValue(columnId))
          : (row.getValue(columnId) ?? '');
      return safeValue.toLowerCase().includes(filterValue.toLowerCase());
    },
    enableRowSelection: (row) =>
      isDisableableItem(row.original)
        ? !row.original.disabled && !!enableRowSelection
        : !!enableRowSelection,
    autoResetExpanded: false,
    autoResetPageIndex: false,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGroupingChange: setGrouping,
    getRowCanExpand: () => !!renderRowSubComponent,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getRowId,
  });

  const isClearButtonVisible =
    data.length !== table.getExpandedRowModel().rows.length;
  const rowData = table.getExpandedRowModel().flatRows;
  const selectedRows = table.getSelectedRowModel().rows;
  const visibleColumns = table
    .getVisibleFlatColumns()
    .map((c) => c.columnDef.header)
    .filter((header): header is string => typeof header === 'string');
  const allColumns = table
    .getAllFlatColumns()
    .filter((header) => typeof header.columnDef.header === 'string');
  const colSpan = table.getVisibleFlatColumns().length;

  // Only set the selection to the filtered data and not to all data
  useEffect(() => {
    if (
      !selectedRows.every((row) => rowData.map((s) => s.id).includes(row.id))
    ) {
      const includedSelection = selectedRows
        .map((s) => s.id)
        .filter((row) => rowData.map((r) => r.id).includes(row));

      setRowSelection(
        Object.fromEntries(includedSelection.map((row) => [row, true])),
      );
    }
  }, [rowData, selectedRows]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: // biome-ignore lint/correctness/useExhaustiveDependencies: only react on rowData.length change
  useEffect(() => {
    if (dataCallback) {
      dataCallback(rowData);
    }
  }, [dataCallback, rowData.length]);

  useEffect(() => {
    localStorage.setItem(
      `table-columns-${id}`,
      JSON.stringify(columnVisibility),
    );
  }, [columnVisibility, id]);

  useEffect(() => {
    if (initialState?.rowSelection) {
      setRowSelection(initialState.rowSelection);
    }
  }, [initialState?.rowSelection]);

  // Jump to first page if global filter is applied and search result is not on current page
  useEffect(() => {
    const filteredListMatches = table
      .getExpandedRowModel()
      .rows.map((r) => r.index)
      .some((r) =>
        table
          .getRowModel()
          .rows.map((r) => r.index)
          .includes(r),
      );
    if (!filteredListMatches && globalFilter) {
      table.setPageIndex(0);
    }
  }, [
    table.getRowModel,
    table.getExpandedRowModel,
    globalFilter,
    table.setPageIndex,
  ]);

  return (
    <div className="flex flex-col" data-test-id={dataTestId}>
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="bg-white border border-neutral-200 print:border-none rounded-xl shadow-sm print:shadow-none dark:bg-neutral-900 dark:border-neutral-700 overflow-auto max-h-[75vh]">
            {(enableGlobalFilter ||
              (enableRowSelection &&
                selectedRows.length &&
                renderSelectedAction) ||
              enableColumnSelection ||
              enableColumnFilter ||
              renderAdditionalAction ||
              enableCsvExport) && (
              <div className="flex px-6 py-4 border-b border-neutral-200 dark:border-neutral-700 justify-between">
                <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
                  <div>
                    {enableGlobalFilter && (
                      <div className="flex space-x-2 items-center">
                        <Input
                          data-test-id="table-global-filter"
                          type="text"
                          value={globalFilter}
                          placeholder={intl.formatMessage(
                            {
                              id: 'Search records',
                            },
                            {
                              records: table.getExpandedRowModel().rows.length,
                            },
                          )}
                          icon={<HiOutlineMagnifyingGlass />}
                          onChangeValue={(e) => setGlobalFilter(e)}
                        />
                        <Transition show={isClearButtonVisible}>
                          <Tooltip
                            content={
                              <StyledButton
                                onClick={() => {
                                  table.resetColumnFilters(true);
                                  setGlobalFilter('');
                                  setParams();
                                  table.resetGrouping();
                                }}
                              >
                                <HiOutlineXMark className="size-5" />
                              </StyledButton>
                            }
                          >
                            <FormattedMessage id="Clear filters" />
                          </Tooltip>
                        </Transition>
                      </div>
                    )}
                  </div>
                  <div className="flex space-x-2">
                    {enableRowSelection &&
                      selectedRows.length > 0 &&
                      renderSelectedAction &&
                      // Do not render any menu if there are no permissions for any items available
                      renderSelectedAction(
                        selectedRows,
                        table.resetRowSelection,
                      ).filter((a) => a && userRoles?.includes(a.permission))
                        .length && (
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <MenuButton
                            data-test-id="selected-actions"
                            className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-neutral-700 shadow-sm align-middle hover:bg-neutral-50 transition-all text-sm dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-white dark:focus:ring-offset-neutral-800"
                          >
                            <HiOutlineBolt className="size-5" />
                            <FormattedMessage id="Selected" />
                            <span className="pl-2 text-xs font-semibold text-primary-600 border-l border-neutral-200 dark:border-neutral-700 dark:text-primary-500">
                              {selectedRows.length}
                            </span>
                          </MenuButton>

                          <MenuItems
                            portal
                            anchor="bottom start"
                            transition
                            className="w-max mt-1 z-30 overflow-y-auto dark:ring-neutral-700 bg-white dark:bg-neutral-800 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none text-base md:text-sm transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
                          >
                            {renderSelectedAction(
                              selectedRows,
                              table.resetRowSelection,
                            ).map(
                              (action) =>
                                action && (
                                  <PrivateWrapper
                                    key={action.text}
                                    roleRequired={action.permission}
                                  >
                                    <Button
                                      data-test-id={
                                        action.dataTestId ?? undefined
                                      }
                                      onClick={action.onClick}
                                      className="hover:bg-neutral-100 dark:hover:bg-neutral-700 text-neutral-900 dark:text-white text-sm px-4 py-2 flex text-left w-full"
                                    >
                                      <div className="flex space-x-2 items-center">
                                        <div>{action.icon}</div>
                                        <div>{action.text}</div>
                                      </div>
                                    </Button>
                                  </PrivateWrapper>
                                ),
                            )}
                          </MenuItems>
                        </Menu>
                      )}

                    {enableColumnSelection && (
                      <Select
                        renderValue={(v) => v?.toString() ?? ''}
                        hidePreview
                        dataTestId="columns-selector"
                        value={visibleColumns}
                        options={allColumns.map((c) => c.columnDef.header)}
                        onChangeSelected={(selected) => {
                          for (const c of allColumns) {
                            selected?.includes(c.columnDef.header)
                              ? c.toggleVisibility(true)
                              : c.toggleVisibility(false);
                          }
                        }}
                      />
                    )}
                    {enableColumnFilter && <FilterMenu table={table} />}
                    {enableCsvExport && (
                      <CSVLink
                        headers={visibleColumns}
                        data={
                          table.getFilteredRowModel().rows.map((row) =>
                            row
                              .getVisibleCells()
                              // Remove columns like expander, delete, edit, ...
                              .filter((cell) => cell.column.getCanGroup())
                              .map((cell) => cell.getValue() ?? '-'),
                          ) as Data
                        }
                        filename={`${id}-export.csv`}
                      >
                        <StyledButton className="!border-neutral-200 !bg-white !text-neutral-700 hover:!bg-neutral-50 dark:!bg-neutral-900 dark:hover:!bg-neutral-800 dark:!border-neutral-700 dark:!text-neutral-400 dark:hover:!text-white">
                          <div className="flex space-x-2 items-center">
                            <div>
                              <HiOutlineArrowDownTray className="size-5" />
                            </div>
                            <div>
                              <FormattedMessage id="Export" />
                            </div>
                          </div>
                        </StyledButton>
                      </CSVLink>
                    )}
                  </div>
                </div>
                <div>{renderAdditionalAction}</div>
              </div>
            )}

            <table
              data-test-id="table"
              className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700"
            >
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        scope="col"
                        className="bg-neutral-50 dark:bg-neutral-800 border-b dark:border-b-neutral-700 sticky z-30 top-0 pl-6 [&>*:not(:first-child)]:lg:pl-3 [&>*:not(:first-child)]:xl:pl-0 [&>*:not(:first-child)]:pr-6 py-3 text-left space-y-1"
                      >
                        {header.isPlaceholder ? null : (
                          <div className="flex items-center gap-x-2">
                            {header.column.getCanGroup() && (
                              // If the header can be grouped, let's add a toggle
                              <Button
                                onClick={header.column.getToggleGroupingHandler()}
                                className="cursor-pointer print:hidden"
                              >
                                {header.column.getIsGrouped() ? (
                                  <Tooltip
                                    content={
                                      <p>
                                        <HiOutlineRectangleStack className="size-5" />
                                      </p>
                                    }
                                  >
                                    <>
                                      <FormattedMessage id="Toggle GroupBy" />
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                      )}
                                    </>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    content={
                                      <p>
                                        <HiOutlineDocument className="size-5" />
                                      </p>
                                    }
                                  >
                                    <>
                                      <FormattedMessage id="Toggle GroupBy" />
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                      )}
                                    </>
                                  </Tooltip>
                                )}
                              </Button>
                            )}
                            <Button
                              className={`flex items-center text-xs font-semibold uppercase tracking-wide text-neutral-800 dark:text-neutral-200 ${
                                header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : ''
                              }`}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {{
                                asc: (
                                  <HiOutlineBarsArrowUp className="size-5 pl-1 print:hidden" />
                                ),
                                desc: (
                                  <HiOutlineBarsArrowDown className="size-5 pl-1 print:hidden" />
                                ),
                              }[header.column.getIsSorted() as string] ??
                                (header.column.getCanSort() && (
                                  <HiOutlineArrowsUpDown className="size-5 pl-1 print:hidden" />
                                ))}
                            </Button>
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody className="divide-y divide-neutral-200 dark:divide-neutral-700 print:text-xxs">
                {table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <tr>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className="h-px w-px whitespace-nowrap"
                        >
                          <div className="pl-5 [&>*:not(:first-child)]:lg:pl-3 [&>*:not(:first-child)]:xl:pl-0 [&>*:not(:first-child)]:pr-6 last:pr-6 py-3">
                            <div
                              className={`flex items-center gap-x-3 ${
                                [DELETE_ID, ACTION_ID].includes(cell.column.id)
                                  ? 'justify-end'
                                  : ''
                              }`}
                            >
                              <RenderedCell<T> cell={cell} row={row} />
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    {!row.getIsGrouped() &&
                      row.getIsExpanded() &&
                      renderRowSubComponent && (
                        <tr>
                          {/* 2nd row is a custom 1 cell row */}
                          <td colSpan={row.getVisibleCells().length}>
                            {renderRowSubComponent.render(row)}
                          </td>
                        </tr>
                      )}
                  </Fragment>
                ))}
                <SkeletonLoader
                  loading={loading && !data.length}
                  rows={table.getState().pagination.pageSize}
                  columns={colSpan}
                />
              </tbody>
            </table>
            {enablePagination && (
              <Pagination
                previousPage={table.previousPage}
                nextPage={table.nextPage}
                canPreviousPage={table.getCanPreviousPage()}
                canNextPage={table.getCanNextPage()}
                pageCount={table.getPageCount()}
                pageIndex={table.getState().pagination.pageIndex}
                total={table.getExpandedRowModel().rows.length}
                gotoPage={table.setPageIndex}
                pageSize={table.getState().pagination.pageSize}
                setPageSize={table.setPageSize}
                enablePageSize={enablePageSize!}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
